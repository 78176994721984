import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ImageStrip = loadable(() => import('/src/components/PageBuilder/layouts/standard/ImageStrip'))

const InsurerLogoStrip = ({ className }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            insurerLogoStrip {
                                fieldGroupName
                                insurerLogoStrip {
                                    imageList {
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 100)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    const classes = ['c-insurer-logo-strip', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.insurerLogoStrip.insurerLogoStrip

        return (
            <ImageStrip
                key={index}
                backgroundColour={'other'}
                imageList={componentData.imageList}
                className={concatenatedClasses}
            />
        )
    })
}

export default InsurerLogoStrip
